import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { TabsProps } from '@app/src/components/Tabs'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { generatePath, Route, Switch, useParams } from 'react-router-dom'
import AssessmentInsightsScene from './AssessmentInsightsScene'
import AssessmentMethodologyScene from './AssessmentMethodologyScene'

export enum AssessmentExploreViews {
  Overview = 'overview',
  Methodology = 'methodology',
}

interface AssessmentExploreSceneParams {
  view: AssessmentExploreViews
}

const AssessmentsExploreScene: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { view } = useParams<AssessmentExploreSceneParams>()
  const { assessmentTemplateId } = useParams<{ assessmentTemplateId: string }>()

  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)

  const actionTabs: TabsProps['tabs'] = [
    {
      type: AssessmentExploreViews.Overview,
      url: generatePath(paths.exploreAssessment, { view: AssessmentExploreViews.Overview, assessmentTemplateId }),
      label: formatMessage({ id: 'assessment.tabs.overview' }),
    },
    {
      type: AssessmentExploreViews.Methodology,
      url: generatePath(paths.exploreAssessment, { view: AssessmentExploreViews.Methodology, assessmentTemplateId }),
      label: formatMessage({ id: 'assessment.tabs.methodology' }),
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: view,
      },
    })
  }, [view])

  if (!canAccessAssessmentTemplate) return null

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.assessments, {
          view: AssessmentExploreViews.Overview,
        })}
      />
    )
  }

  return (
    <>
      <Switch>
        <Route
          path={generatePath(paths.exploreAssessment, { view: AssessmentExploreViews.Overview, assessmentTemplateId })}
          exact
        >
          <AssessmentInsightsScene
            tabs={{ actionTabs, activeTabParam: view }}
            assessmentTemplateId={assessmentTemplateId}
          />
        </Route>
        <Route
          path={generatePath(paths.exploreAssessment, {
            view: AssessmentExploreViews.Methodology,
            assessmentTemplateId,
          })}
          exact
        >
          <AssessmentMethodologyScene
            tabs={{ actionTabs, activeTabParam: view }}
            assessmentTemplateId={assessmentTemplateId}
          />
        </Route>
      </Switch>
    </>
  )
}

export default AssessmentsExploreScene
