import { AssessmentData } from '@app/src/pages/Assessments/useOrganizedAssessmentData'
import { AssessmentType } from '@app/src/types/resourceExplorer'
import React from 'react'
import AssessmentPieChartCell from './AssessmentPieChartCell'
import LevelCell from './LevelCell'
import ValueCell from './ValueCell'

type AssessmentCellProps = {
  assessment?: AssessmentData
  disableCell?: boolean
}

const AssessmentCell: React.FC<AssessmentCellProps> = ({ assessment, disableCell }) => {
  if (!assessment) {
    return <ValueCell />
  }
  if (assessment.assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment) {
    return <LevelCell value={assessment.totalLevel} disableCell={disableCell} />
  }

  return <AssessmentPieChartCell assessment={assessment} disableCell={disableCell} />
}

export default AssessmentCell
