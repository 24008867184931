import { default as endpoints } from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import CategoryOptionLabel from '@app/src/components/CategoryOptionLabel'
import LinkButton from '@app/src/components/LinkButton'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useGetFilterLabels } from '@app/src/hooks/getFilterLabels'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RequestAutomation } from '@app/src/types/automations'
import { Category } from '@app/src/types/categories'
import { Roles } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Alert, AlertTitle, Box, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

const CATEGORY_OPTIONS_NAME = 'categoryOptions.id'

const flattenAndMerge = (arr: FilterGroup[][]): { name: string; value: string[] }[] => {
  const result: Record<string, Set<string>> = {}

  arr.flat().forEach(item => {
    if (!result[item.name]) {
      result[item.name] = new Set()
    }
    item.filters.forEach(filter => {
      ;(Array.isArray(filter.value) ? filter.value : []).forEach(value => result[item.name].add(String(value)))
    })
  })

  return Object.entries(result).map(([name, values]) => ({
    name,
    value: Array.from(values),
  }))
}

interface RequestAutomationWarningAlertProps {
  automationType: RequestAutomationType
  providerType?: string
  currentCategory?: Category
  isCustomCategoryOption?: boolean
  sx?: SxProps<Theme>
}

const AlertLoader = ({ canGoToAutomation }: { canGoToAutomation: boolean }) => {
  return (
    <Stack my={-2}>
      <Skeleton width={350} height={50} />
      {canGoToAutomation && <Skeleton width={100} height={50} />}
    </Stack>
  )
}

const oneMinute = 60 * 1000
const tenMinutes = 10 * 60 * 1000
const baseAlertStyle = {
  '& .MuiAlert-icon': {
    alignSelf: 'flex-start',
    marginTop: 1,
  },
  '& .MuiAlert-message': {
    overflowY: 'hidden',
  },
}

const RequestAutomationWarningAlert: React.FC<RequestAutomationWarningAlertProps> = ({
  automationType,
  providerType,
  currentCategory,
  isCustomCategoryOption,
  sx,
}) => {
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { formatMessage } = useIntl()
  const { role } = useAuthentication().scope
  const canGoToAutomation = role === Roles.Admin || role === Roles.WfAdmin
  const { getFilterLabel } = useGetFilterLabels({})

  const automationRedirectUrl = stringifyQueryFilters({
    url: generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.Automations }),
    queryParams: {},
  })

  const { items: automations, isLoading: isAutomationsLoading } = useFetchCollectionWithPost<RequestAutomation>({
    payload: {
      filter: [],
      include: [],
    },
    key: FetchKey.Automation,
    options: { staleTime: oneMinute, cacheTime: tenMinutes },
    endpoint: endpoints.automations,
  })

  const hasAutomationForNewProvider = Boolean(
    automations.find(automation => automation.automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER),
  )

  const hasAutomationForCategory = Boolean(
    automations.find(automation => automation.automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY),
  )

  const automationsFilters = automations.map(automation => automation.categoryFilters ?? [])

  const flattenedAutomationFilters = flattenAndMerge(automationsFilters)

  const propertyNames = flattenedAutomationFilters
    .filter(filter => filter.name !== CATEGORY_OPTIONS_NAME)
    ?.filter(filter => filter.name !== 'country.id')
    ?.filter(filter => filter.name !== 'mappingNodes.actorTypeModel.id')
    ?.map(filters => formatMessage({ id: `dashboard.sourcing.companyStatistics.${filters.name}` }))

  const categoryOptionsFilter = flattenedAutomationFilters.find(filter => filter.name === CATEGORY_OPTIONS_NAME)

  const { data: categories, isLoading: isCategoriesLoading } = useFetchCollectionWithPost<Category>({
    key: FetchKey.Category,
    endpoint: endpoints.categoryGetWithIds,
    payload: {
      filter: [
        {
          name: CATEGORY_OPTIONS_NAME,
          filters: [
            {
              value: categoryOptionsFilter?.value,
              operator: Operators.In,
            },
          ],
        },
      ],
      include: [],
    },
    options: {
      enabled: Boolean(categoryOptionsFilter),
    },
  })

  const currentCategoryValues = flattenedAutomationFilters.find(
    category => category.name === (isCustomCategoryOption ? CATEGORY_OPTIONS_NAME : currentCategory?.name),
  )?.value

  const categoryNames = categories?.items?.map(category => category.name)

  const isLoading = isAutomationsLoading || isCategoriesLoading

  const alertStyle = { ...baseAlertStyle, ...sx }

  const renderAutomationsButton = () =>
    canGoToAutomation && (
      <Box display="flex" justifyContent="end" ml={-1} pt={1} mb={-1}>
        <LinkButton to={automationRedirectUrl ? automationRedirectUrl : ''}>
          <Typography variant="button">
            {formatMessage({ id: 'schemas.provider.requestAutomationGoToPage' })}
          </Typography>
        </LinkButton>
      </Box>
    )

  if (isCustomCategoryOption) {
    return (
      <>
        {currentCategoryValues ? (
          <Alert severity="info" sx={alertStyle}>
            <>
              {isLoading ? (
                <AlertLoader canGoToAutomation={canGoToAutomation} />
              ) : (
                <>
                  <AlertTitle>
                    {formatMessage(
                      { id: `schemas.provider.requestAutomationCustomCategoriesWarning` },
                      {
                        categories: currentCategoryValues.map((v, i) => (
                          <CategoryOptionLabel
                            key={v}
                            categoryOptionId={String(v)}
                            categoryCount={currentCategoryValues.length}
                            isLastItem={i === currentCategoryValues.length - 1}
                          />
                        )),
                      },
                    )}
                  </AlertTitle>
                  {renderAutomationsButton()}
                </>
              )}
            </>
          </Alert>
        ) : null}
      </>
    )
  }

  if (currentCategory) {
    return (
      <>
        {currentCategoryValues ? (
          <Alert severity="info" sx={alertStyle}>
            {isLoading ? (
              <AlertLoader canGoToAutomation={canGoToAutomation} />
            ) : (
              <>
                <AlertTitle>
                  {formatMessage(
                    { id: 'schemas.provider.requestAutomationCustomCategoriesWarning' },
                    { categories: getFilterLabel(currentCategory.name, currentCategoryValues) },
                  )}
                </AlertTitle>
                {renderAutomationsButton()}
              </>
            )}
          </Alert>
        ) : null}
      </>
    )
  }

  if (hasAutomationForNewProvider && automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER) {
    return (
      <Alert severity="info" sx={alertStyle}>
        {isLoading ? (
          <AlertLoader canGoToAutomation={canGoToAutomation} />
        ) : (
          formatMessage(
            { id: 'resourceCollections.create.requestAutomationOnNewProviderWarning' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }) },
          )
        )}
      </Alert>
    )
  }

  if (hasAutomationForCategory && automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY) {
    return (
      <Alert severity="info" sx={alertStyle}>
        {isLoading ? (
          <AlertLoader canGoToAutomation={canGoToAutomation} />
        ) : (
          formatMessage(
            { id: 'schemas.provider.requestAutomationCategoriesWarning' },
            { categories: [...propertyNames, ...(categoryNames ?? [])].join(', ') },
          )
        )}
      </Alert>
    )
  }

  return null
}

export default RequestAutomationWarningAlert
