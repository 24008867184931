import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import { ResponseExportColumnsAccessor } from '@app/src/export-columns/response'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import ResponseHeader from '@app/src/pages/ResourceCollection/Collections/Response/AccessorResponseHeader'
import ResponseRow from '@app/src/pages/ResourceCollection/Collections/Response/AccessorResponseRow'
import ResponsesFilters from '@app/src/pages/ResourceCollection/Filters/ResponsesFilters'
import { FilterGroup, RESPONSE_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { Response } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import React from 'react'
import { useIntl } from 'react-intl'
import { ViewTypeName } from './ManageRequestsScene'

interface AccessorResponseSceneProps {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}
const allowedFilters = [
  'request.template.id',
  'externalVerificationStatus',
  'request.subscriptions.target.organization.countryId',
  'request.subscriptions.target.organization.id',
  'request.periodName',
  'submittedAt',
  'internalVerificationStatus',
  'request.subscriptions.target.providerApprovalStatus',
  'request.subscriptions.target.priority',
  'request.subscriptions.target.finalRiskRating',
  'request.subscriptions.target.activityStatus',
  'request.subscriptions.target.supplierUsage',
  'request.subscriptions.target.tier',
  'request.subscriptions.target.ownerUserId',
  'request.subscriptions.target.categoryOptions.id',
  'request.subscriptions.target.spends.amount',
  'request.subscriptions.target.spends.periodName',
  'request.inquiries.status',
  'request.subscriptions.target.countryId',
  'verifications.internalVerificationStatus',
  'request.subscriptions.target.assessments.totalLevel',
  'request.subscriptions.target.assessments.intervalType',
  'request.subscriptions.target.assessments.section1Level',
  'request.subscriptions.target.assessments.section2Level',
  'request.subscriptions.target.assessments.section3Level',
  'request.subscriptions.target.assessments.section4Level',
  'request.subscriptions.target.assessments.section5Level',
  'request.subscriptions.target.assessments.assessmentTemplate.name',
]

const AccessorResponseScene: React.FC<AccessorResponseSceneProps> = ({
  embedded,
  tabs,
  actionButtons = [],
  relatedContentFilter = [],
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()

  const basePayload = {
    filter: [...relatedContentFilter, RESPONSE_LATEST_SUBMITTED_FILTER],
    sort: sorting,
    include: [
      'creatorOrganization',
      'request.responderOrganization',
      'request.template',
      'request.template.image',
      'request.subscriptions.target',
      'request.inquiries',
      'request.target.organization',
    ],
  }

  const rawExportPayload = {
    ...basePayload,
    include: [...basePayload.include, 'request.target'],
  }

  const payload = {
    ...basePayload,
    include: [...basePayload.include],
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.manageRequests' })}
        tabs={tabs}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Response}
                  count={count}
                  userFilter={apiFilters}
                  exportColumns={ResponseExportColumnsAccessor}
                  rawExportPayload={rawExportPayload}
                  onlyLatest
                />,
              ),
            disabled: tabs?.activeTabParam === ViewTypeName.Overview || isLoading || count === 0,
          },
          ...actionButtons,
        ]}
        filter={
          <ResponsesFilters
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
      >
        <Table<Response>
          RowComponent={({ row }) => <ResponseRow row={row} />}
          HeaderComponent={() => <ResponseHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          data={items}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default AccessorResponseScene
