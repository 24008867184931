import {
  AssessmentRule,
  AssessmentRuleType,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
  QuestionInTemplate,
} from '@app/src/types/resourceExplorer'
import { numberIsSet } from '@app/src/utils/helpers'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ClassificationRowProps {
  item?: QuestionAnswerClassificationRule
  translationKey: string
  questionId?: number
  rules?: AssessmentRule[]
  showChip?: boolean
}

export const getSxByRank = (rank?: QuestionAnswerRank) => {
  switch (rank) {
    case QuestionAnswerRank.Positive:
      return {
        color: 'common.white',
        bgcolor: 'brandDecorative.emerald',
      }
    case QuestionAnswerRank.Negative:
      return {
        color: 'primary',
        bgcolor: 'error.main',
      }
    case QuestionAnswerRank.NegativeAdverse:
      return {
        color: 'common.white',
        bgcolor: 'semantic.error',
      }
    default:
      return {}
  }
}

export const sortClassificationBasedOnRuleType = (
  questionAnswerClassifications: QuestionInTemplate['questionAnswerClassificationRules'] = [],
) => {
  const definedQuestionAnswerClassifications = questionAnswerClassifications.filter(item => item?.rank !== 'NotSet')

  const optionClassifications = definedQuestionAnswerClassifications.filter(
    answerClassification => answerClassification?.ruleType === AssessmentRuleType.SelectedValue,
  )
  const numberClassifications = definedQuestionAnswerClassifications.filter(
    answerClassification =>
      answerClassification?.ruleType === AssessmentRuleType.NumberEqualTo ||
      answerClassification?.ruleType === AssessmentRuleType.NumberGreaterThan ||
      answerClassification?.ruleType === AssessmentRuleType.NumberLowerThan,
  )
  const answerClassifications = definedQuestionAnswerClassifications.filter(
    answerClassification => answerClassification?.ruleType === AssessmentRuleType.AnswerStatus,
  )

  return { optionClassifications, numberClassifications, answerClassifications }
}

const ClassificationRow: React.FC<ClassificationRowProps> = ({
  item,
  translationKey,
  questionId,
  rules,
  showChip = true,
}) => {
  const { formatMessage } = useIntl()

  const showPoints = Boolean(rules) && numberIsSet(questionId)

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      key={item?.rank}
      borderTop="1px solid"
      borderColor="divider"
      py={1.5}
      px={2}
    >
      <Typography sx={{ flex: 3, mr: 2 }}>
        {formatMessage({ id: translationKey }, { ruleType: item?.ruleType?.toString(), value: item?.value })}
      </Typography>
      <Box
        display="flex"
        justifyContent={showPoints ? 'left' : 'flex-end'}
        flex={showPoints ? 1 : 'none'}
        minWidth={showPoints ? 'auto' : '120px'}
      >
        {showChip && (
          <Chip
            label={formatMessage({
              id: `templateBuilder.answerClassifications.classificationsRanks.${item?.rank}`,
            })}
            size="small"
            sx={{ ...getSxByRank(item?.rank) }}
          />
        )}
      </Box>
      {showPoints && (
        <Typography sx={{ flex: 1, textAlign: 'right', minWidth: '120px' }}>
          {formatMessage(
            { id: 'baseAssessment.providerPage.points' },
            {
              points: rules?.find(p => p.value === item?.rank && p.questionId === questionId)?.points || 0,
            },
          )}
        </Typography>
      )}
    </Stack>
  )
}

export default ClassificationRow
