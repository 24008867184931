import Permissions, { usePermissions } from '@app/src/auth/permissions'
import RequestTypeCardSelection from '@app/src/components/CreateNewRequestModal/RequestTypeSelection'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import { insertIf } from '@app/src/utils/helpersTs'
import { Logos } from '@app/src/wf-constants'
import { AttachMoneyOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type RequestTypeProps = {
  setReportType: (reportType: ReportType) => void
  onClose: () => void
}

const RequestType: React.FC<RequestTypeProps> = ({ setReportType, onClose }) => {
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { hasPermission } = usePermissions()

  const edciPermission = hasPermission(Permissions.EDCI_ENABLED)
  const sfdrPermission = hasPermission(Permissions.INVESTMENT_USER)

  const currentOrg = accessibleOrganizations.find(org => org?.organization?.id === account?.organization?.id)

  const requestTypeCards = [
    {
      onClick: () => setReportType(ReportType.WF),
      title: formatMessage({ id: 'form.createRequest.mainMenu.wfReportTitle' }),
      description: formatMessage({ id: 'form.createRequest.mainMenu.wfReportBody' }),
      logo: Logos.WorldfavorLogoSquareFilledBlack,
      whiteBg: true,
    },
    {
      onClick: () => setReportType(ReportType.COMPANY),
      title: formatMessage(
        { id: 'form.createRequest.mainMenu.companyReportTitle' },
        { companyName: account?.organization?.name },
      ),
      description: formatMessage({ id: 'form.createRequest.mainMenu.companyReportBody' }),
      logo: currentOrg?.organization?.image?.url,
    },
    ...insertIf(sfdrPermission, {
      onClick: () => setReportType(ReportType.SFDR_WITH_COMPANIES),
      title: formatMessage(
        { id: 'form.createRequest.mainMenu.sfdrReportTitle' },
        { companyName: account?.organization?.name },
      ),
      description: formatMessage({ id: 'form.createRequest.mainMenu.sfdrReportBody' }),
      icon: <AttachMoneyOutlined />,
    }),
    ...insertIf(edciPermission, {
      onClick: () => setReportType(ReportType.EDCI),
      title: formatMessage(
        { id: 'form.createRequest.mainMenu.edciReportTitle' },
        { companyName: account?.organization?.name },
      ),
      description: formatMessage({ id: 'form.createRequest.mainMenu.edciReportBody' }),
      logo: '/img/edci.svg',
    }),
  ]
  return (
    <CreationModalContainer onClose={onClose} overflow="visible" showProgress={false}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Box mb={6}>
          <Typography variant="h1">{formatMessage({ id: 'form.createRequest.mainMenu.title' })}</Typography>
        </Box>
        <RequestTypeCardSelection requestTypeCards={requestTypeCards} />
      </Box>
    </CreationModalContainer>
  )
}

export default RequestType
