import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodField'
import { PROVIDER_SELECTOR_ALLOWED_FILTERS } from '@app/src/components/CreateNewRequestModal/ProviderSelectorFilterDrawer'
import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep from '@app/src/components/CreationModal/CreationModalStep'
import { ReportType, useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useQueryState } from '@app/src/hooks/queryState'
import AutomationCategories from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationCategories'
import AutomationDeadlineOffset from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationDeadlineOffset'
import AutomationPeriod from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationPeriod'
import AutomationQuestionnaires from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationQuestionaires'
import AutomationQuestionnaireType from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationQuestionaireType'
import AutomationSummary from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationSummary'
import AutomationType from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationType'
import useAutomationAffectedCount from '@app/src/pages/Configurations/ConfigurationsPages/Automation/Hooks/useAutomationAffectedCount'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RequestAutomation } from '@app/src/types/automations'
import { getPeriodOrderNumber, getPeriodType } from '@app/src/utils'
import { getDateWithoutTimeFromString, insertIf } from '@app/src/utils/helpersTs'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Dialog, Skeleton } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

interface CreateAutomationModalProps {
  onClose: () => void
  open: boolean
  editedAutomation?: RequestAutomation
}

export enum AUTOMATION_QUESTION_KEYS {
  AUTOMATION_TYPE = 'automationType',
  CATEGORY_FILTERS = 'categoryFilters',
  REPORT_TYPE = 'reportType',
  TEMPLATES = 'templates',
  DATE_TYPE = 'dateType',
  DATE_YEAR = 'dateYear',
  DATE_PERIOD = 'datePeriod',
  DEADLINE_OFFSET = 'deadlineOffsetDays',
  REVIEW = 'review',
}

export interface AutomationFormData {
  id?: number
  reportType: ReportType
  automationType: RequestAutomationType
  templates: Array<{ id: number; title: string }>
  dateType: Periods
  categoryFilters?: FilterGroup[]
  dateYear: string
  datePeriod: number
  deadlineOffsetDays?: number
}

interface AutomationQuestion {
  title: string
  description?: React.ReactNode
  fieldName: AUTOMATION_QUESTION_KEYS | ''
  children: JSX.Element
  overrideButtonTextId?: string | undefined
  hideNextButton?: boolean
  hasSkipButton?: boolean
  disableInitialBack?: boolean
}

const mapToFormData = (requestAutomation: RequestAutomation): AutomationFormData => {
  const periodType = getPeriodType(requestAutomation.periodStartsAt, requestAutomation.periodEndsAt)
  const isStandardReport = requestAutomation.templateAutomations?.[0]?.template.isStandardReport

  return {
    id: requestAutomation.id,
    reportType: isStandardReport ? ReportType.WF : ReportType.COMPANY,
    automationType: requestAutomation.automationType,
    categoryFilters: requestAutomation.categoryFilters,
    templates: requestAutomation.templateAutomations.map(t => ({ id: t.template.id, title: t.template.title })),
    deadlineOffsetDays: requestAutomation.deadlineOffSetDays,
    dateType: periodType,
    dateYear: getDateWithoutTimeFromString(requestAutomation.periodStartsAt).getFullYear().toString(),
    datePeriod: getPeriodOrderNumber(requestAutomation.periodStartsAt, periodType),
  }
}

const CreateAutomationModal: React.FC<CreateAutomationModalProps> = ({ onClose, open, editedAutomation }) => {
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const { formatMessage } = useIntl()
  const { mutateAsync: createAutomation, isLoading: isAutomationLoading } = useUpdateResource<
    RequestAutomation,
    unknown
  >()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const [, setQueryFilters] = useQueryState('filters', [])

  const formMethods = useForm<AutomationFormData>({
    shouldUnregister: false,
    defaultValues: {
      [AUTOMATION_QUESTION_KEYS.TEMPLATES]: [],
      [AUTOMATION_QUESTION_KEYS.DATE_TYPE]: Periods.Yearly,
      [AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS]: [],
    },
  })

  useEffect(() => {
    if (!editedAutomation) {
      reset({})
      return
    }

    const automationFormData = mapToFormData(editedAutomation)
    reset(automationFormData)
  }, [editedAutomation?.id])

  const { watch, handleSubmit, reset } = formMethods
  const reportType = watch(AUTOMATION_QUESTION_KEYS.REPORT_TYPE)
  const requestAutomationType = watch(AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE)

  const { affectedCount, isLoadingAffectedCount } = useAutomationAffectedCount(formMethods)

  const getReviewStepDescription = (): React.ReactNode => {
    const firstPart = formatMessage({
      id: editedAutomation
        ? 'form.createAutomation.summary.descriptionEditAutomation'
        : 'form.createAutomation.summary.descriptionNewAutomation',
    })

    if (requestAutomationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER)
      return (
        <>
          {firstPart} {formatMessage({ id: 'form.createAutomation.summary.descriptionForEveryNewProvider' })}
        </>
      )

    if (isLoadingAffectedCount) return <Skeleton variant="text" />
    const affectedCompaniesTextId = editedAutomation
      ? 'form.createAutomation.summary.descriptionForProvidersInCategoryEdited'
      : 'form.createAutomation.summary.descriptionForProvidersInCategoryNew'
    const affectedCompaniesText = formatMessage(
      {
        id: affectedCompaniesTextId,
      },
      {
        b: (chunks: React.ReactNode) => <b>{chunks}</b>,
        count: affectedCount ?? 0,
      },
    )
    return (
      <>
        {firstPart} {affectedCompaniesText}
      </>
    )
  }

  const QUESTIONS: AutomationQuestion[] = [
    ...insertIf(!editedAutomation, {
      title: formatMessage({ id: 'form.createAutomation.automationType.title' }),
      description: formatMessage({ id: 'form.createAutomation.automationType.description' }),
      fieldName: AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE,
      children: <AutomationType />,
      hideNextButton: true,
      disableInitialBack: true,
    }),
    ...insertIf(requestAutomationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY, {
      title: formatMessage({ id: 'form.createAutomation.selectCategories.title' }),
      description: formatMessage({ id: 'form.createAutomation.selectCategories.description' }),
      fieldName: AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS,
      children: <AutomationCategories />,
    }),
    ...insertIf(!editedAutomation, {
      title: formatMessage({ id: 'form.createRequest.mainMenu.title' }),
      fieldName: AUTOMATION_QUESTION_KEYS.REPORT_TYPE,
      children: <AutomationQuestionnaireType />,
      hideNextButton: true,
    }),
    {
      title: formatMessage({ id: 'form.createAutomation.selectQuestionnaire.title' }),
      description: formatMessage({
        id:
          requestAutomationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER
            ? 'form.createAutomation.selectQuestionnaire.descriptionForEveryNewProvider'
            : 'form.createAutomation.selectQuestionnaire.descriptionForProvidersInCategory',
      }),
      fieldName: AUTOMATION_QUESTION_KEYS.TEMPLATES,
      children: <AutomationQuestionnaires />,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.selectPeriod.title' }),
      description: formatMessage({
        id:
          reportType === ReportType.COMPANY
            ? 'form.createAutomation.selectPeriod.descriptionCompanySpecific'
            : 'form.createAutomation.selectPeriod.descriptionWfStandard',
      }),
      fieldName: AUTOMATION_QUESTION_KEYS.DATE_YEAR,
      children: <AutomationPeriod />,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.deadlineOffset.title' }),
      description: formatMessage({
        id: 'form.createAutomation.deadlineOffset.description',
      }),
      fieldName: AUTOMATION_QUESTION_KEYS.DEADLINE_OFFSET,
      children: <AutomationDeadlineOffset />,
      hasSkipButton: true,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.summary.title' }),
      overrideButtonTextId: editedAutomation
        ? 'form.createAutomation.summary.saveAutomation'
        : 'form.createAutomation.summary.finalizeAutomation',
      description: getReviewStepDescription(),
      fieldName: '',
      children: <AutomationSummary />,
    },
  ]

  useEffect(() => {
    setQueryFilters([])
    if (!open) {
      setActiveStep(1)
      reset({})
    }
  }, [open])

  const onSubmit = async (values: AutomationFormData) => {
    const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
      dateType: values.dateType,
      datePeriod: values.datePeriod,
      dateYear: values.dateYear,
    })

    const body = {
      id: editedAutomation?.id,
      templateIds: values.templates.map(template => template.id),
      periodStartsAt,
      periodEndsAt,
      deadlineOffsetDays: values.deadlineOffsetDays,
      automationType: values.automationType,
      categoryFilters: values.categoryFilters,
    }

    await createAutomation(
      { body: body, url: endpoints.saveAutomation },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.automationCreated' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.Automation)
          onClose()
        },
      },
    )
  }

  const activeQuestion = useMemo(
    () => QUESTIONS[activeStep - 1],
    [activeStep, QUESTIONS.map(q => q.title).join(','), QUESTIONS.length, Boolean(editedAutomation)],
  )

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <Filters allowedFilters={PROVIDER_SELECTOR_ALLOWED_FILTERS}>
        {({ clearQueryFilter }) => (
          <CreationModalContainer
            title={formatMessage({ id: `configurations.${editedAutomation ? 'editAutomation' : 'automation'}` })}
            onClose={() => {
              clearQueryFilter()
              onClose()
              setActiveStep(1)
            }}
          >
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {activeQuestion && (
                  <CreationModalStep
                    title={activeQuestion.title}
                    description={activeQuestion.description}
                    fieldnames={[activeQuestion.fieldName].filter(Boolean)}
                    onBack={() => undefined}
                    hideNextButton={activeQuestion.hideNextButton}
                    disableInitialBack={activeQuestion.disableInitialBack}
                    hasSkipButton={activeQuestion.hasSkipButton}
                    isLoading={isAutomationLoading}
                    overrideButtonTextId={activeQuestion.overrideButtonTextId}
                  >
                    {activeQuestion.children}
                  </CreationModalStep>
                )}
              </form>
            </FormProvider>
          </CreationModalContainer>
        )}
      </Filters>
    </Dialog>
  )
}

export default CreateAutomationModal
