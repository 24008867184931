import {
  FiveIntervalChartIconFive,
  FiveIntervalChartIconFour,
  FiveIntervalChartIconOne,
  FiveIntervalChartIconThree,
  FiveIntervalChartIconTwo,
} from '@app/src/pages/Assessments/icons/FiveIntervals'
import {
  ThreeIntervalChartIconOne,
  ThreeIntervalChartIconThree,
  ThreeIntervalChartIconTwo,
} from '@app/src/pages/Assessments/icons/ThreeIntervals'
import { AssessmentData } from '@app/src/pages/Assessments/useOrganizedAssessmentData'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'

type PieChartCellProps = {
  assessment: AssessmentData
  disableCell?: boolean
}

const IconBasedOnInterval: React.FC<{ intervals: number; percentage: number }> = ({ intervals, percentage }) => {
  if (intervals === 3) {
    if (percentage <= 33) {
      return <ThreeIntervalChartIconOne />
    } else if (percentage <= 66) {
      return <ThreeIntervalChartIconTwo />
    } else {
      return <ThreeIntervalChartIconThree />
    }
  }

  if (percentage <= 20) {
    return <FiveIntervalChartIconOne />
  } else if (percentage <= 40) {
    return <FiveIntervalChartIconTwo />
  } else if (percentage <= 60) {
    return <FiveIntervalChartIconThree />
  } else if (percentage <= 80) {
    return <FiveIntervalChartIconFour />
  } else {
    return <FiveIntervalChartIconFive />
  }
}

const PieChartCell: React.FC<PieChartCellProps> = ({ assessment, disableCell }) => {
  const { formatMessage } = useIntl()
  const intervals = assessment?.assessmentTemplate.levels ?? 5
  const levelTranslationKey =
    assessment.assessmentTemplate.levels === 3
      ? `assessments.levels.3.level${assessment.totalLevel}`
      : `assessments.levels.5.level${assessment.totalLevel}`

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip
        arrow
        title={formatMessage(
          { id: 'assessments.assessedAsLevel' },
          { level: formatMessage({ id: levelTranslationKey }) },
        )}
      >
        <Box display="inline flex" alignItems="center">
          <IconBasedOnInterval intervals={intervals} percentage={assessment.percentage ?? 0} />
          <Typography ml={1}>
            {formatMessage({ id: 'general.percentage' }, { value: assessment.percentage })}
          </Typography>
        </Box>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default PieChartCell
