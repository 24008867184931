import { FetchKey } from '@app/src/api/fetchHooks'
import { SortOrder } from '@app/src/types/filter'
import { Chip } from '@mui/material'
import React from 'react'
import Filter from './Filter'
import FilterFacetSelect from './FilterFacetSelect'
import OptionWithLabel from './OptionWithLabel'
import { FilterGroup, Operators } from './useFilters'

interface LevelFilterProps {
  filterName: string
  fieldLabel: string
  assessmentFilterNamePrefix?: string
  implicitFilters: FilterGroup[]
  fetchKey: FetchKey
  endpoint: string
}

const LevelFilter: React.FC<LevelFilterProps> = ({
  filterName,
  fieldLabel,
  assessmentFilterNamePrefix,
  implicitFilters,
  fetchKey,
  endpoint,
}) => {
  return (
    <Filter
      operator={Operators.In}
      name={`${assessmentFilterNamePrefix ? `${assessmentFilterNamePrefix}.` : ''}${filterName}`}
    >
      {({ value, onChange, filterName }) => (
        <FilterFacetSelect
          implicitFilters={implicitFilters}
          size="small"
          multiple
          facetsParam={{
            key: [fetchKey, filterName],
            endpoint: endpoint,
            facetsParam: [{ name: filterName, isEnum: true }],
            sort: {
              target: 'label',
              order: SortOrder.ASCENDING,
            },
          }}
          renderOption={(props, option, { selected }) => (
            <OptionWithLabel
              key={option.label}
              props={props}
              label={`${option?.label}/5`}
              multiple
              selected={selected}
            />
          )}
          renderTags={(value, getTagProps) =>
            value?.map(
              (option, index) =>
                option && (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.value.toString() || index}
                    label={`${option?.label}/5`}
                    size="small"
                  />
                ),
            )
          }
          filterName={filterName}
          onChange={value => onChange(value)}
          fieldLabel={fieldLabel}
          value={[value].flat()}
        />
      )}
    </Filter>
  )
}

export default LevelFilter
