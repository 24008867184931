import ActionButtons, { ActionButton } from '@app/src/components/ActionButtons'
import LoadingButton from '@app/src/components/LoadingButton'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Alert, Box, Button, ButtonProps, Divider, Stack, Typography } from '@mui/material'
import React, { ReactNode, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import CreationModalStepSplitView from './CreationModalStepSplitView'

export type CreationModalStepProps = {
  title: string
  fieldnames: Array<string>
  description?: ReactNode
  hasSkipButton?: boolean
  children: ReactNode
  onBack?: () => void
  disableInitialBack?: boolean
  overrideButtonTextId?: string
  hideNextButton?: boolean
  isLoading?: boolean
  widthOverride?: number
  extraButtons?: Array<ActionButton>
  nextButtonProps?: ButtonProps
  informationAlert?: ReactNode
  splitView?: {
    enabled: boolean
    component: ReactNode
  }
}

const CreationModalStep: React.FC<CreationModalStepProps> = ({
  title,
  fieldnames,
  description,
  children,
  hasSkipButton,
  onBack,
  disableInitialBack = false,
  overrideButtonTextId,
  isLoading = false,
  widthOverride,
  hideNextButton,
  extraButtons,
  nextButtonProps,
  informationAlert,
  splitView,
}) => {
  const { activeStep, totalSteps, setActiveStep, enableEmptyState, allowsNextWithoutData } = useCreationModalProgress()
  const { formatMessage } = useIntl()
  const { setValue, watch } = useFormContext()

  const canGoPrev = useMemo(() => activeStep > 1, [activeStep])
  const isLastStep = useMemo(() => activeStep === totalSteps, [activeStep, totalSteps])

  const handleBack = () => {
    if (canGoPrev) {
      setActiveStep(currentValue => currentValue - 1)
    } else {
      onBack?.()
    }
  }

  const fieldValuesWatch = fieldnames.map(field => watch(field))

  const canGoNext = useMemo(() => {
    if (allowsNextWithoutData || hideNextButton) {
      return true
    }
    const hasNextStep = activeStep < totalSteps + 1
    const hasValue = fieldValuesWatch.every(fieldValue =>
      Array.isArray(fieldValue)
        ? Boolean(fieldValue.length)
        : typeof fieldValue === 'number'
          ? Number.isFinite(fieldValue)
          : Boolean(fieldValue),
    )

    return hasNextStep && hasValue
  }, [activeStep, totalSteps, fieldValuesWatch, allowsNextWithoutData])

  if (splitView?.enabled && splitView.component) {
    return (
      <CreationModalStepSplitView
        title={title}
        fieldnames={fieldnames}
        description={description}
        children={children}
        hasSkipButton={hasSkipButton}
        disableInitialBack={disableInitialBack}
        overrideButtonTextId={overrideButtonTextId}
        isLoading={isLoading}
        hideNextButton={hideNextButton}
        extraButtons={extraButtons}
        nextButtonProps={nextButtonProps}
        splitView={splitView}
        canGoNext={canGoNext}
        handleBack={handleBack}
        isLastStep={isLastStep}
      />
    )
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" width={widthOverride ?? 780}>
      {!enableEmptyState && (
        <Stack spacing={3} mb={2}>
          <Typography variant="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="span">
            {description}
          </Typography>
        </Stack>
      )}
      <Box pt={4}>{children}</Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
        {disableInitialBack && activeStep === 1 ? (
          <Box />
        ) : (
          <Button onClick={handleBack} startIcon={<ArrowBack />}>
            {formatMessage({ id: 'general.back' })}
          </Button>
        )}

        {isLastStep ? (
          <Box display="flex" gap={1}>
            {extraButtons && <ActionButtons buttons={extraButtons} />}
            <LoadingButton
              loading={isLoading}
              variant="contained"
              endIcon={!isLastStep && <ArrowForward />}
              type="submit"
              disabled={!canGoNext}
              {...nextButtonProps}
            >
              {formatMessage({ id: overrideButtonTextId ?? `form.createRequest.submit` })}
            </LoadingButton>
          </Box>
        ) : (
          <Box display="flex" gap={1}>
            {hasSkipButton && (
              <Box mr={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    fieldnames.forEach(fieldName => {
                      setValue(fieldName, undefined)
                    })
                    setActiveStep(currentValue => currentValue + 1)
                  }}
                >
                  {formatMessage({ id: 'general.skip' })}
                </Button>
              </Box>
            )}

            {extraButtons && <ActionButtons buttons={extraButtons} />}

            {!enableEmptyState && !hideNextButton && (
              <Button
                variant="contained"
                endIcon={!isLastStep && <ArrowForward />}
                disabled={!canGoNext}
                onClick={() => setActiveStep(currentValue => currentValue + 1)}
                data-testid="continue-button"
                {...nextButtonProps}
              >
                {formatMessage({
                  id:
                    overrideButtonTextId ?? (activeStep + 1 === totalSteps ? 'general.reviewAndSend' : 'general.next'),
                })}
              </Button>
            )}
          </Box>
        )}
      </Box>

      {informationAlert && (
        <>
          <Divider sx={{ my: 4 }} />
          <Alert variant="filled" severity="info" sx={{ backgroundColor: 'common.white', alignItems: 'flex-start' }}>
            {informationAlert}
          </Alert>
        </>
      )}
    </Box>
  )
}

export default CreationModalStep
