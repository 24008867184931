import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep, { CreationModalStepProps } from '@app/src/components/CreationModal/CreationModalStep'
import EmailPreview from '@app/src/components/ReferralContact/EmailPreview'
import SendInviteTimeline from '@app/src/components/ReferralContact/SendInviteTimeline'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Provider } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { CloseOutlined, RemoveRedEyeOutlined, SendOutlined } from '@mui/icons-material'
import { Dialog, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import ConnectSuccessScreen from './ConnectSuccessScreen'
import ProvidersList from './ProvidersList'

export enum CONNECT_PROVIDERS_QUESTION_KEYS {
  PROVIDER_IDS = 'providerIds',
  OVERVIEW = 'overview',
}

type CreateAutomationModalProps = {
  providers: Provider[]
  open: boolean
  onClose: () => void
}

export type ConnectProvidersFormData = {
  providerIds: Array<number>
}

const ConnectProvidersModal: React.FC<CreateAutomationModalProps> = ({ providers, open, onClose }) => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { trackEvent } = useAmplitude()
  const { showErrorNotification } = useErrorNotification()
  const { mutate: sendBulkInvite, isLoading: isSendingInvite } =
    useCreateResource<ConnectProvidersFormData['providerIds']>()
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const formMethods = useForm<ConnectProvidersFormData>({
    shouldUnregister: false,
    defaultValues: {
      [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS]: [],
    },
  })
  const { handleSubmit, reset, watch } = formMethods
  const [isSplitView, setIsSplitView] = useState(false)
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [selectedProviderIds, setSelectedProviderIds] = useState<Array<number>>([])

  const watchProviders = watch(CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS)

  const QUESTIONS: CreationModalStepProps[] = [
    {
      title: formatMessage({ id: 'form.connectProviders.invite.title' }),
      description: (
        <Typography>
          {formatMessage(
            { id: 'form.connectProviders.invite.description' },
            {
              count: selectedProviderIds.length,
              hasMissingContacts: Boolean(selectedProviderIds.length - watchProviders.length),
              missingCount: selectedProviderIds.length - watchProviders.length,
            },
          )}
        </Typography>
      ),
      fieldnames: [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS],
      children: (
        <ProvidersList
          providers={providers}
          selectedProviderIds={selectedProviderIds}
          setSelectedProviderIds={setSelectedProviderIds}
        />
      ),
      disableInitialBack: true,
      overrideButtonTextId: 'general.continue',
    },
    {
      title: formatMessage({ id: 'form.connectProviders.review.title' }),
      description: formatMessage({ id: 'form.connectProviders.review.description' }, { count: watchProviders.length }),
      fieldnames: [CONNECT_PROVIDERS_QUESTION_KEYS.OVERVIEW],
      children: <SendInviteTimeline />,
      hideNextButton: true,
      disableInitialBack: true,
      overrideButtonTextId: 'referral.sendInvite.title',
      extraButtons: [
        {
          label: formatMessage({
            id: isSplitView ? 'referral.sendInvite.closePreview' : 'referral.sendInvite.previewEmail',
          }),
          variant: 'outlined',
          startIcon: isSplitView ? <CloseOutlined /> : <RemoveRedEyeOutlined />,
          onClick: () => setIsSplitView(prev => !prev),
        },
      ],
      nextButtonProps: {
        startIcon: <SendOutlined />,
      },
      informationAlert: formatMessage({ id: 'form.connectProviders.review.informationAlert' }),
      splitView: {
        enabled: isSplitView,
        component: <EmailPreview provider={providers[0]} />,
      },
      isLoading: isSendingInvite,
    },
  ]

  const activeQuestion = useMemo(() => QUESTIONS[activeStep - 1], [activeStep, QUESTIONS, selectedProviderIds])

  const onSubmit = (values: ConnectProvidersFormData) => {
    sendBulkInvite(
      {
        url: endpoints.sendBulkInviteToReferralContacts,
        body: values.providerIds,
      },
      {
        onSuccess: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.InviteSupplier.InvitationSent,
            eventProps: {
              invitation_type: 'bulk',
              count: values.providerIds.length,
            },
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          setShowSuccessScreen(true)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  useEffect(() => {
    if (open) {
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.InviteSupplier.InvitationViewed,
        eventProps: {
          invitation_type: 'bulk',
        },
      })
    }
  }, [open])

  useEffect(() => {
    reset({
      [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS]: providers
        .filter(provider => Boolean(provider.referralContact))
        .map(provider => provider.id),
    })
    setSelectedProviderIds(providers.map(provider => provider.id))

    return () => {
      setActiveStep(1)
      reset()
      setShowSuccessScreen(false)
    }
  }, [open])

  useEffect(() => {
    setSelectedProviderIds(prev => {
      const filteredProviders = providers.filter(provider => prev.includes(provider.id))

      reset({
        [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS]: filteredProviders
          .filter(provider => Boolean(provider.referralContact))
          .map(provider => provider.id),
      })

      return filteredProviders.map(provider => provider.id)
    })
  }, [providers])

  useEffect(() => {
    if (!providers.length) {
      onClose()
    }
  }, [providers.length])

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  useEffect(() => {
    setIsSplitView(false)
  }, [activeStep])

  return (
    <Dialog open={open} onClose={onClose} fullScreen closeAfterTransition>
      {showSuccessScreen ? (
        <ConnectSuccessScreen
          missingContactProviderIds={providers
            .filter(provider => !provider.referralContact)
            .filter(provider => selectedProviderIds.includes(provider.id))
            .map(provider => provider.id)}
          invitedProviderIds={watchProviders}
          onClose={onClose}
        />
      ) : (
        <CreationModalContainer
          title={
            activeStep === 1
              ? formatMessage({ id: 'form.connectProviders.header.review' })
              : formatMessage({ id: 'form.connectProviders.header.overview' })
          }
          onClose={onClose}
          disablePadding={isSplitView}
        >
          <FormProvider {...formMethods}>
            <Stack component="form" flexGrow={1} alignItems="center" onSubmit={handleSubmit(onSubmit)}>
              {activeQuestion && (
                <CreationModalStep {...activeQuestion} widthOverride={584}>
                  {activeQuestion.children}
                </CreationModalStep>
              )}
            </Stack>
          </FormProvider>
        </CreationModalContainer>
      )}
    </Dialog>
  )
}

export default ConnectProvidersModal
