import React from 'react'
import { useIntl } from 'react-intl'
import { AssessmentTemplate, AssessmentType, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { Close } from '@mui/icons-material'
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material'
import AssessmentModalTable from '../ResourceCollection/Collections/DataHub/DataInsights/AssessmentsModalTable'

type AssessmentDialogProps = {
  assessmentTemplate: AssessmentTemplate
  activeLevel?: number
  period?: number
  riskType?: RiskType
  riskStatus?: RiskStatus
  providerIds?: Array<number>
  isOpen: boolean
  closeModal: () => void
  countryName?: string
}

const AssessmentDialog: React.FC<AssessmentDialogProps> = ({
  assessmentTemplate,
  activeLevel,
  period,
  riskType,
  riskStatus,
  providerIds,
  isOpen,
  closeModal,
  countryName,
}) => {
  const { formatMessage } = useIntl()
  const isBaselineAssessment = assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment

  const renderContent = () => {
    const levelLabel = !activeLevel
      ? undefined
      : isBaselineAssessment
        ? formatMessage({ id: 'baseAssessment.totalLevel' }, { value: activeLevel })
        : assessmentTemplate.levels === 3
          ? formatMessage({ id: `assessments.levelsWithPercentageSpan.3.level${activeLevel}` })
          : formatMessage({ id: `assessments.levelsWithPercentageSpan.5.level${activeLevel}` })

    return (
      <>
        <Box px={4} py={1} bgcolor="grey.300">
          <Typography color="textSecondary">
            {[
              formatMessage({ id: 'assessments.breadcrumbsRootWord' }),
              assessmentTemplate.name,
              period,
              levelLabel,
              riskStatus ? formatMessage({ id: `schemas.risk.${riskStatus}` }) : undefined,
              countryName,
            ]
              .filter(Boolean)
              .join(' / ')}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1} height={10} pl={4} bgcolor="grey.100">
          <AssessmentModalTable
            assessmentTemplate={assessmentTemplate}
            activeLevel={activeLevel}
            period={period}
            riskType={riskType}
            riskStatus={riskStatus}
            providerIds={providerIds}
          />
        </Box>
      </>
    )
  }

  return (
    <Dialog open={isOpen} onClose={closeModal} fullScreen sx={{ m: 10 }} PaperProps={{ sx: { borderRadius: 2 } }}>
      <Box height="100%" margin={0} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" py={2} px={4}>
          <Stack>
            <Typography variant="h6">{formatMessage({ id: 'assessments.title' })}</Typography>
          </Stack>

          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>

        {isOpen && renderContent()}
      </Box>
    </Dialog>
  )
}

export default AssessmentDialog
