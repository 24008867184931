import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ListItem, ListItemAvatar, ListItemText, Skeleton, TableCell, TableRow } from '@mui/material'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import SwitchCell from '@app/src/components/Table/Cells/SwitchCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { AssessmentType, OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'

interface OrganizationAssessmentProps {
  row: OrganizationAssessmentSettings
}

interface OrganizationAssessmentSettingSaveModel {
  assessmentTemplateId: number
  isActivated: boolean
}

export const AssessmentSettingsRow: React.FC<OrganizationAssessmentProps> = ({ row }) => {
  const { formatMessage } = useIntl()
  const [isActivated, setIsActivated] = useState(row.isActivated)
  const [isDisabled, setIsDisabled] = useState(false)
  const { mutateAsync, isLoading } = useUpdateResource<OrganizationAssessmentSettingSaveModel>()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const { assessments } = useFlags()

  const handleChange = async () => {
    const newActivatedState = !isActivated
    setIsActivated(newActivatedState)

    if (newActivatedState) {
      showSnackbar({
        message: formatMessage(
          { id: 'notifications.successfullyUpdatedAssessment' },
          { name: row.assessmentTemplate.name },
        ),
        severity: NotificationSeverity.success,
      })

      setIsDisabled(true)
      setTimeout(() => setIsDisabled(false), 120000) // 2 minutes
    }

    mutateAsync(
      {
        url: endpoints.organizationAssessmentSettings,
        body: {
          assessmentTemplateId: row.assessmentTemplate.id,
          isActivated: newActivatedState,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Analyze.Assessment.Toggled,
            eventProps: {
              assessment_name: row.assessmentTemplate.name,
              assessment_id: row.assessmentTemplate.id,
              enabled: newActivatedState,
            },
          })

          queryClient.invalidateQueries(FetchKey.OrganizationAssessmentSettings)
          queryClient.invalidateQueries(FetchKey.Account)
        },
        onError: () => {
          setIsActivated(!newActivatedState)
          if (newActivatedState) {
            setIsDisabled(false)
          }
          showSnackbar({
            message: formatMessage({ id: 'notifications.toggleAssessmentSettingsFailed' }),
            severity: NotificationSeverity.error,
          })
        },
      },
    )
  }

  const renderAssessmentRow = () => (
    <TableRow>
      <SwitchCell
        sx={{ ml: 2 }}
        disabled={isLoading || isDisabled}
        checked={isActivated}
        onChange={handleChange}
        label={
          isLoading ? (
            <Skeleton width={10} height={20} variant="text" />
          ) : (
            formatMessage({ id: isActivated ? 'automations.on' : 'automations.off' })
          )
        }
      />
      <TableCell>
        <ListItem component="div">
          <ListItemAvatar>
            <Avatar
              size={AvatarSize.XXL}
              sx={({ palette }) => ({
                backgroundColor: isActivated ? palette.secondary.main : palette.grey[400],
                color: isActivated ? palette.common.white : palette.grey[600],
                mr: 2,
              })}
            >
              <FactCheckOutlinedIcon fontSize="large" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={row.assessmentTemplate.name} secondary={row.assessmentTemplate.description} />
        </ListItem>
      </TableCell>
    </TableRow>
  )

  if (row.assessmentTemplate.assessmentType === AssessmentType.StandardAssessment && !assessments) {
    return null
  }

  return renderAssessmentRow()
}

export default AssessmentSettingsRow
