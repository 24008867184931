import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Provider } from '@app/src/types/organizations'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'

interface AssessmentCompareHeaderProps extends SortableHeaderProps {
  selectedProviders: Array<number>
  setSelectedProviders: React.Dispatch<React.SetStateAction<Array<number>>>
  providersInPage: Provider[]
  assessmentTemplateAndPeriod: string[]
}

const AssessmentCompareHeader: React.FC<AssessmentCompareHeaderProps> = ({
  toggleSorting,
  activeSorting,
  selectedProviders,
  setSelectedProviders,
  providersInPage,
  assessmentTemplateAndPeriod,
}) => {
  const { formatMessage } = useIntl()
  const isChecked = providersInPage?.every(provider => selectedProviders?.includes(provider.id))

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedProviders(prev => _.uniq([...prev, ...providersInPage.map(provider => provider.id)]))
    } else {
      setSelectedProviders([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.companyName' })}
        sorting={{ name: 'provider.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />

      {assessmentTemplateAndPeriod.map(label => (
        <TableHeaderCell key={label} label={label} minWidth={TableCellWidth.MEDIUM} />
      ))}
    </TableRow>
  )
}

export default AssessmentCompareHeader
