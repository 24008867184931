import React, { useState } from 'react'

import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'
import AssessmentSettingsHeader from './AssessmentSettingsHeader'
import AssessmentSettingsRow from './AssessmentSettingsRow'
import { useFlags } from 'launchdarkly-react-client-sdk'

const AssessmentConfig: React.FC = () => {
  const { account } = useAccount()
  const { assessments } = useFlags()

  const {
    data: assessmentSettings = [],
    isLoading,
    isError,
  } = useFetchResource<OrganizationAssessmentSettings[]>({
    key: FetchKey.OrganizationAssessmentSettings,
    endpoint: endpoints.organizationAssessmentSettings,
    options: {
      enabled: Boolean(account?.organization),
      keepPreviousData: true,
    },
  })

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE)
  const pageCount = assessments ? assessmentSettings?.length ?? 0 : Math.min(1, assessmentSettings?.length ?? 0)

  return (
    <>
      <ConfigurationCollection enablePadding={{ top: false, right: true }}>
        <Table<OrganizationAssessmentSettings>
          HeaderComponent={AssessmentSettingsHeader}
          RowComponent={AssessmentSettingsRow}
          count={pageCount}
          data={assessmentSettings}
          isLoading={isLoading}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ConfigurationCollection>
    </>
  )
}

export default AssessmentConfig
