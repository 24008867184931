import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import CustomCategoriesFilters from '@app/src/pages/ResourceCollection/Filters/CustomCategoriesFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import OptionWithLabel from '@app/src/pages/ResourceCollection/Filters/OptionWithLabel'
import RangeFilter from '@app/src/pages/ResourceCollection/Filters/RangeFilter'
import TextFieldFilter from '@app/src/pages/ResourceCollection/Filters/TextFieldFilter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { Box, Button, Chip, createFilterOptions } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem, FiltersProps } from '..'
import PreFetchedSingleFilterFacet from './PreFetchedSingleFilterFacet'

interface StandardAssessmentFiltersProps extends FiltersProps {
  assessmentTemplate: AssessmentTemplate
}

const StandardAssessmentFilters: React.FC<StandardAssessmentFiltersProps> = ({
  allowedFilters,
  resetPage,
  implicitFilters,
  assessmentTemplate,
}) => {
  const { formatMessage } = useIntl()
  const { productMapping } = useFlags()
  const { openDrawer } = useDrawer()

  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  implicitFilters = [
    ...(implicitFilters ?? []),
    {
      name: 'assessmentTemplateId',
      filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }],
    },
  ]

  const has5Levels = assessmentTemplate.levels !== 3 // null or 5 shows 5 levels to be backward compatible

  return (
    <Box display="flex">
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, inDrawerFiltersCount, clearQueryFilter }) => {
          const isFiltered = Object.keys(filters).length > 1

          return (
            <>
              <Box display="flex" flexGrow={1} marginRight={1}>
                <Box flex={8} marginRight={1}>
                  <Filter operator={Operators.In} name="provider.id">
                    {({ value, onChange, filterName }) => (
                      <FilterFacetSelect
                        implicitFilters={implicitFilters}
                        size="small"
                        multiple
                        facetsParam={{
                          key: [FetchKey.AssessmentFacets, filterName],
                          endpoint: endpoints.assessmentFacet,
                          facetsParam: [{ name: 'provider.name' }],
                        }}
                        filterName={filterName}
                        onChange={value => onChange(value)}
                        fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                        value={[value].flat()}
                      />
                    )}
                  </Filter>
                </Box>
                <Box flex={1} marginLeft={1} minWidth={120}>
                  <Filter name="periodName" operator={Operators.EqualTo}>
                    {({ value, onChange, filterName }) => (
                      <PreFetchedSingleFilterFacet
                        implicitFilters={implicitFilters}
                        endpoint={endpoints.assessmentFacet}
                        filterName={filterName}
                        onChange={value => onChange(value, true)}
                        fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                        value={[value].flat()}
                        sort={{ target: 'label', order: SortOrder.DESCENDING }}
                        isEnum
                      />
                    )}
                  </Filter>
                </Box>
              </Box>

              <Box>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.assessments' })}>
                              <Filter operator={Operators.In} name="totalLevel">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.AssessmentFacets, filterName],
                                      endpoint: endpoints.assessmentFacet,
                                      facetsParam: [{ name: 'totalLevel', isEnum: true }],
                                      sort: {
                                        target: 'label',
                                        order: SortOrder.ASCENDING,
                                      },
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id:
                                            (has5Levels
                                              ? 'assessments.levelsWithPercentageSpan.5.level'
                                              : 'assessments.levelsWithPercentageSpan.3.level') + option?.label,
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option.value.toString() || index}
                                              label={formatMessage({
                                                id:
                                                  (has5Levels
                                                    ? 'assessments.levelsWithPercentageSpan.5.level'
                                                    : 'assessments.levelsWithPercentageSpan.3.level') + option?.label,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'assessments.interval' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.companies' })}>
                              <Filter operator={Operators.In} name="provider.id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.AssessmentFacets, filterName],
                                      endpoint: endpoints.assessmentFacet,
                                      facetsParam: [{ name: 'provider.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter operator={Operators.In} name="provider.country.id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.AssessmentFacets, filterName],
                                      endpoint: endpoints.assessmentFacet,
                                      facetsParam: [{ name: 'provider.country.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="provider.linkStatus">
                                {({ value, filterName, onChange }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.AssessmentFacets, filterName],
                                      endpoint: endpoints.assessmentFacet,
                                      facetsParam: [{ name: filterName }],
                                      sort: {
                                        order: SortOrder.ASCENDING,
                                        target: 'value',
                                      },
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.linkStatus' })}
                                    value={[value].flat()}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `resourceExplorer.provider.status.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option.value.toString() || index}
                                              label={formatMessage({
                                                id: `resourceExplorer.provider.status.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                  />
                                )}
                              </Filter>
                              <RangeFilter
                                uniqueIdFrom="createdFrom"
                                uniqueIdTo="createdTo"
                                name="provider.createdAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.filter.createdFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.filter.createdTo' })}
                              />
                              <Filter operator={Operators.Contains} name="provider.websiteAddress">
                                {({ filterName, operator, value }) => (
                                  <TextFieldFilter
                                    defaultValue={value}
                                    value={value}
                                    filterName={filterName}
                                    operator={operator}
                                    size="small"
                                    label={formatMessage({ id: 'schemas.organization.websiteAddress' })}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.Contains} name="provider.vatNumber">
                                {({ filterName, operator, value }) => (
                                  <TextFieldFilter
                                    defaultValue={value}
                                    value={value}
                                    filterName={filterName}
                                    operator={operator}
                                    size="small"
                                    fullWidth
                                    label={formatMessage({ id: 'schemas.organization.vatNumber' })}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.Contains} name="provider.registrationNumber">
                                {({ filterName, operator, value }) => (
                                  <TextFieldFilter
                                    defaultValue={value}
                                    value={value}
                                    filterName={filterName}
                                    operator={operator}
                                    size="small"
                                    fullWidth
                                    label={formatMessage({ id: 'schemas.organization.registrationNumber' })}
                                  />
                                )}
                              </Filter>
                            </FilterSection>

                            <FilterSection
                              defaultCollapsed
                              label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                            >
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.ownerUserId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.activityStatus">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.activityStatus', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.activityStatusValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.activityStatusValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.finalRiskRating">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.finalRiskRating', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.finalRiskRatingValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.priority', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.providerApprovalStatus">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.providerApprovalStatus', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>

                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.supplierUsage">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.supplierUsage', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.supplierUsageValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.tier">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.tier', isEnum: true }],
                                      }}
                                      filterOptions={(options, state) => [...tierFilter(options, state)]}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage(
                                            {
                                              id: `schemas.provider.tierValues`,
                                            },
                                            { tier: option?.label ?? '' },
                                          )}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage(
                                                  {
                                                    id: `schemas.provider.tierValues`,
                                                  },
                                                  { tier: option.label },
                                                )}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </FilterSection>
                            <CustomCategoriesFilters baseFilterName="provider" />

                            {productMapping && (
                              <FilterSection defaultCollapsed label={formatMessage({ id: 'navbar.productMapping' })}>
                                <Filter operator={Operators.In} name="provider.mappingNodes.actorTypeModel.id">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.mappingNodes.actorTypeModel.name' }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.mappingNode.actorTypeValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                                <Filter operator={Operators.In} name="provider.mappingNodes.tier">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.AssessmentFacets, filterName],
                                        endpoint: endpoints.assessmentFacet,
                                        facetsParam: [{ name: 'provider.mappingNodes.tier', isEnum: true }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </FilterSection>
                            )}
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Box>
            </>
          )
        }}
      </Filters>
    </Box>
  )
}

export default StandardAssessmentFilters
